<template>
  <div id="ckEditor4">
    <ckeditor
      :config="editorConfig"
      :id="editorId"
      :ref="editorId"
      @ready="init"
      v-if="flag"
      v-model="content"
    ></ckeditor>
  </div>
</template>
<script>
import xss from "xss";
export default {
  props: {
    height: {
      type: Number,
      default: 200,
    },
    placeholder: {
      type: String,
      default: "请输入",
    },
  },
  data() {
    return {
      flag: true,
      editorConfig: {
        allowedContent: true,
        height: this.height,
        resize_enabled: false, // 禁止拖拽
        scayt_autoStartup: false, // 即时拼写检查
        // image_previewText: "",
        // filebrowserImageUploadUrl: "/File/UpLoadImg",
        // filebrowserUploadUrl: "/File/UpLoadImg",
        // uploadUrl: "/File/UpLoadImg",
        // extraPlugins: 'myVideo'
        editorplaceholder: this.placeholder,
        toolbarGroups: [
          { name: "document", groups: ["mode", "document", "doctools"] },
          { name: "clipboard", groups: ["clipboard", "undo"] },
          {
            name: "editing",
            groups: ["find", "selection", "spellchecker", "editing"],
          },
          { name: "forms", groups: ["forms"] },
          // '/',
          { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
          },
          { name: "links", groups: ["links"] },
          { name: "insert", groups: ["insert"] },
          // '/',
          { name: "styles", groups: ["styles"] },
          { name: "colors", groups: ["colors"] },
          { name: "tools", groups: ["tools"] },
          { name: "others", groups: ["others"] },
          { name: "about", groups: ["about"] },
        ],
        removeButtons:
          "About,ShowBlocks,Styles,Font,Flash,Image,Table,Smiley,SpecialChar,PageBreak,Iframe,HorizontalRule,Link,Unlink,Anchor,CreateDiv,Language,CopyFormatting,Superscript,Subscript,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,SelectAll,Find,Replace,Redo,Undo,Cut,Copy,Paste,PasteText,PasteFromWord,Templates,Save,NewPage,Preview,Print,Scayt",
        extraPlugins: "button,panelbutton,colorbutton",
      },
      content: "",

      editorId: "",
      editor: "",
      readied: false,
      timeout: "",
    };
  },
  created: function () {
    this.editorId = `ck_${this._uid}`;
  },
  deactivated: function () {
    this.flag = false;
  },
  activated: function () {
    console.log("activated ck", this.editorId);
    if (!this.flag) {
      this.$nextTick(()=>{
        this.flag = true
      })
    }
  },
  mounted: function () {
    console.log("ckeditor id", this.editorId);
  },
  methods: {
    init() {
      let that = this;
      this.editor = this.$refs[this.editorId].instance;
      console.log("ckeditor init", this.editor);
    },
    destroy() {
      // if (this.editor) {
      //   this.editor.destroy()
      //   this.editor = ''
      //   this.readied = false
      // }
      // clearTimeout(this.timeout)
    },
    onfileUploadRequest(a) {
      console.log(a);
    },
    fileUploadRequest(res) {
      console.log(res);
    },
    setData(data) {
      this.content = data;
    },
    getData() {
      return this.content;
    },
  },
};
</script>
<style lang="less">
#ckEditor4 {
  position: relative;
}
</style>
