<template>
  <div id="surveyEdit">
    <Drawer
      :mask-closable="false"
      :title="id ? '详情' : '新增'"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer survey-edit-drawer"
      v-model="flag"
      width="900"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="bk-drawer-body">
        <div class="flex" style="height:100%">
          <div class="questions">
            <div :key="i" class="question" v-for="(a, i) in questions" v-show="a.Isdel!=1">
              <div class="question-h">
                <b style="width:2em;flex-shrink:0">{{a.QuesNumber}}、</b>
                <div :class="a._error_type?'ivu-form-item-error':''">
                  <RadioGroup
                    @on-change="onQuesType(i)"
                    size="small"
                    type="button"
                    v-model="a.TypeId"
                  >
                    <Radio :label="1">单选题</Radio>
                    <Radio :label="2">多选题</Radio>
                    <Radio :label="3">问答题</Radio>
                    <Radio :label="4">打分题</Radio>
                  </RadioGroup>
                </div>
                <div class="flex-sub"></div>
                <Icon @click="delQues(i)" color="#ed4014" type="md-close" />
              </div>
              <div :class="a._error_title?'ivu-form-item-error':''" style="margin-bottom:5px">
                <Input placeholder="题目" size="small" type="textarea" v-model="a.Title" />
              </div>
              <div prop="ChoiceItems" v-show="a.TypeId==1||a.TypeId==2">
                <ul class="ques-choice-ul">
                  <li :key="j" class="ques-choice-li" v-for="(b,j) in a.ChoiceItems">
                    <div class="letter">{{b.Letter}}</div>
                    <div
                      :brn="a.TypeId==2&&j<2"
                      :class="b._error_content ? 'ivu-form-item-error choice-content' : 'choice-content'"
                    >
                      <Input
                        :border="false"
                        :rows="3"
                        name="content"
                        placeholder="选项内容"
                        size="small"
                        type="textarea"
                        v-model="b.Content"
                      />
                    </div>
                    <div :style="a.TypeId==1?{height:'64px'}:{}">
                      <div @click="delChoiceItem(i, j)" class="choice-close" v-if="j>1">
                        <Icon color="#ed4014" type="md-close" />
                      </div>
                      <div
                        :class="b._error_score ? 'ivu-form-item-error choice-score' : 'choice-score'"
                        v-if="a.TypeId==1"
                      >
                        <Input
                          :border="false"
                          name="score"
                          placeholder="分数"
                          size="small"
                          style="width:50px"
                          v-model="b.Score"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
                <Button @click="addChoiceItem(i)" long size="small" type="dashed">
                  <Icon type="md-add" />添加选项
                </Button>
              </div>
              <div class="ques-placeholder" v-if="a.TypeId==3">
                <Input placeholder="输入框提示文字" size="small" v-model="a.Placeholder_" />
              </div>
            </div>
            <Button @click="addQues" ghost long style="background-color:#fff" type="primary">
              <Icon type="md-add" />添加题目
            </Button>
          </div>
          <div class="bill">
            <Form :label-width="80" :model="formd" :rules="rules" @submit.native.prevent ref="form">
              <FormItem label="标题：" prop="Title" required>
                <Input v-model="formd.Title" />
              </FormItem>
              <FormItem label="首语：">
                <!-- <Input :rows="3" type="textarea" v-model="formd.Foreword" /> -->
                <editor ref="editor1" />
              </FormItem>
              <FormItem label="尾语：">
                <!-- <Input :rows="3" type="textarea" v-model="formd.Postscript" /> -->
                <editor ref="editor2" />
              </FormItem>
              <FormItem label="备注：">
                <Input :rows="3" type="textarea" v-model="formd.Remark" />
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
      <div class="bk-drawer-footer" v-if="ABLE">
        <Button
          :loading="posting"
          :to="`/survey/preview?id=${id}`"
          ghost
          style="margin-right:10px"
          target="_blank"
          type="primary"
          v-if="id"
        >预览</Button>
        <Button :loading="posting" @click="onOk('new')" ghost type="primary" v-if="id">保存为新问卷</Button>
        <Button :loading="posting" @click="onOk()" type="primary">保存</Button>
      </div>
    </Drawer>
  </div>
</template>
<script>
import editor from "@/components/CkEditor4";
export default {
  components: { editor },
  data() {
    return {
      ABLE: false,
      flag: false,
      id: 0,
      formd: {},
      rules: {
        Title: [{ required: true, message: " " }],
      },
      loading: false,
      failed: false,
      posting: false,
      questions: [],
      questions_del: [],
      rules2: {
        Title: [{ required: true, message: " " }],
        TypeId: [{ required: true, type: "number", message: " " }],
        ChoiceItems: [
          {
            validator: (rule, value, callback) => {
              console.log(arguments);
              callback();
            },
          },
        ],
      },
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      if (id) {
        this.ABLE = this.$able.map.SurveyMgrSurveyUpd;
        this.id = id;
        this.$nextTick(() => {
          this.req();
        });
      } else {
        this.ABLE = this.$able.map.SurveyMgrSurveyAdd;
        this.formd = {
          TypeId: 1, //	类型(1：满意度调查问卷，2：调研，3：投票)
          TargetUserType: 2, //	目标人员类型（1：内部员工，2：客户）
          CategoryId: 1, // 分类Id（1：客户满意度调查表）
        };
      }
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/CmsSurvey/GetById2/" + this.id)
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data.Bill;
            this.$refs.editor1.setData(this.formd.Foreword || "");
            this.$refs.editor2.setData(this.formd.Postscript || "");
            let ques = res.data.Questions;
            for (let i in ques) {
              if (ques[i].TypeId == 3) {
                if (ques[i].ChoiceDetail) {
                  try {
                    ques[i].Placeholder_ = JSON.parse(
                      ques[i].ChoiceDetail
                    ).Placeholder;
                  } catch (err) {
                    console.log(err);
                  }
                }
              }
            }
            this.questions = res.data.Questions;
          } else {
            this.failed = true;
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    close() {
      this.ABLE = false;
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
      this.id = 0;
      this.questions = [];
      this.questions_del = [];
      this.$refs.editor1.setData("");
      this.$refs.editor2.setData("");
    },
    onOk(action) {
      this.$refs.form.validate((valid) => {
        if (valid && this.validateQues()) {
          if (action == "new") {
            this.postDataAsNew();
          } else {
            this.postData();
          }
        }
      });
    },
    postData() {
      this.posting = true;
      let api =
        this.id > 0 ? "/Api/CmsSurvey/UpdateEx" : "/Api/CmsSurvey/AddEx";
      let params = {
        Bill: this.$util.copy(this.formd),
        Questions: this.questions.concat(this.questions_del),
      };
      params.Bill.Foreword = this.$refs.editor1.getData();
      params.Bill.Postscript = this.$refs.editor2.getData();
      this.$axios
        .post(api, params)
        .then((res) => {
          if (res.result) {
            this.$Message.success(this.id > 0 ? "编辑成功" : "新增成功");
            this.$emit(this.id > 0 ? "on-upd" : "on-add");
            this.close();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
          this.posting = false;
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    postDataAsNew() {
      this.posting = true;
      let params = {
        Bill: this.$util.copy(this.formd),
        Questions: this.questions,
      };
      delete params.Bill.Id;
      delete params.Bill.CreateTime;
      delete params.Bill.UpdateTime;
      for (let i in params.Questions) {
        delete params.Questions[i].Id;
      }
      params.Bill.Foreword = this.$refs.editor1.getData();
      params.Bill.Postscript = this.$refs.editor2.getData();
      this.$axios
        .post("/Api/CmsSurvey/AddEx", params)
        .then((res) => {
          if (res.result) {
            this.$Message.success("新增成功");
            this.$emit(this.id > 0 ? "on-upd" : "on-add");
            this.close();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
          this.posting = false;
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    addQues() {
      this.questions.push({
        QuesNumber: this.questions.length + 1,
        ChoiceItems: [],
      });
    },
    delQues(i) {
      let org = this.$util.copy(this.questions);
      let arr = [];
      for (let index in org) {
        if (index != i) {
          org[index].QuesNumber = arr.length + 1;
          arr.push(org[index]);
        }
      }
      org[i].Isdel = 1;
      this.questions_del.push(org[i]);
      this.questions = arr;
    },
    onQuesType(i) {
      if (this.questions[i].TypeId == 1 || this.questions[i].TypeId == 2) {
        if (this.questions[i].ChoiceItems.length == 0) {
          this.questions[i].ChoiceItems = [
            { Sort: 0, Letter: "A", Content: "", Score: 0 },
            { Sort: 1, Letter: "B", Content: "", Score: 0 },
          ];
        }
      }
      this.questions[i]._error_type = !/^\d$/.test(this.questions[i].TypeId);
    },
    addChoiceItem(i) {
      let j = this.questions[i].ChoiceItems.length;
      this.questions[i].ChoiceItems.push({
        Sort: j,
        Letter: this.$util.index2Abc(j, "ABC"),
        Content: "",
        Score: 0,
      });
    },
    delChoiceItem(i, j) {
      let org = this.$util.copy(this.questions[i].ChoiceItems);
      let arr = [];
      for (let index in org) {
        if (index != j) {
          org[index].Sort = arr.length;
          org[index].Letter = this.$util.index2Abc(arr.length, "ABC");
          arr.push(org[index]);
        }
      }
      this.questions[i].ChoiceItems = arr;
    },
    validateQues() {
      let err = 0;
      for (let i in this.questions) {
        this.questions[i]._error_type = !/^\d$/.test(this.questions[i].TypeId);
        if (this.questions[i]._error_type) {
          err++;
        }
        this.questions[i]._error_title = !this.questions[i].Title;
        if (this.questions[i]._error_title) {
          err++;
        }
        if (this.questions[i].TypeId == 1) {
          for (let j in this.questions[i].ChoiceItems) {
            this.questions[i].ChoiceItems[j]._error_content =
              !this.questions[i].ChoiceItems[j].Content;
            if (this.questions[i].ChoiceItems[j]._error_content) {
              err++;
            }
            this.questions[i].ChoiceItems[j]._error_score = !/^\d+$/.test(
              this.questions[i].ChoiceItems[j].Score
            );
            if (this.questions[i].ChoiceItems[j]._error_score) {
              err++;
            }
          }
        } else if (this.questions[i].TypeId == 2) {
          for (let j in this.questions[i].ChoiceItems) {
            this.questions[i].ChoiceItems[j]._error_content =
              !this.questions[i].ChoiceItems[j].Content;
            if (this.questions[i].ChoiceItems[j]._error_content) {
              err++;
            }
          }
        } else if (this.questions[i].TypeId == 3) {
          if (this.questions[i].Placeholder_) {
            this.questions[i].ChoiceDetail = JSON.stringify({
              Placeholder: this.questions[i].Placeholder_,
            });
          }
        }
      }
      this.questions = this.questions.concat([]);
      return err == 0;
    },
    preview() {},
  },
};
</script>
<style lang="less">
.survey-edit-drawer {
  .bk-drawer-body {
    padding: 0;
    overflow: hidden;
  }
  .questions {
    padding: 10px 5px 10px 10px;
    width: 400px;
    flex-shrink: 0;
    border-right: 1px solid #dcdee2;
    overflow-y: scroll;
    background-color: #f5f7f9;
    .question {
      padding: 10px;
      background-color: #fff;
      border-radius: 4px;
      margin-bottom: 10px;
      border: 1px solid #e8eaec;
      .ivu-icon-md-close {
        cursor: pointer;
      }
    }
    .question-h {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
    .ivu-form-item-error .ivu-radio-inner,
    .ivu-form-item-error .ivu-radio-group-button .ivu-radio-group-item {
      border-color: #ed4014;
    }
    .ques-choice-li {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      border: 1px solid #dcdee2;
      border-radius: 4px;
    }
    .ques-choice-li .letter {
      padding: 4px 7px;
    }
    .ques-choice-li .choice-content {
      border-left: 1px solid #dcdee2;
      border-right: 1px solid #dcdee2;
      flex: 1;
    }
    .ques-choice-li .choice-content[brn] {
      border-right: none;
    }
    .ques-choice-li .choice-content textarea.ivu-input {
      font-size: 12px;
    }
    .ques-choice-li .choice-score .ivu-input {
      font-size: 12px;
    }
    .ques-choice-li .choice-close {
      text-align: center;
      cursor: pointer;
      min-width: 20px;
    }
    .ques-choice-li .choice-close + .choice-score {
      border-top: 1px solid #dcdee2;
    }
    .ques-placeholder .ivu-input {
      font-size: 12px;
    }
  }
  .bill {
    overflow-y: scroll;
    .ivu-form {
      padding: 10px;
    }
    .ivu-form-item {
      width: 100%;
      margin-right: 0;
    }
  }
}
</style>